import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/hero/"
import { Title, Preamble, Text } from "../components/cases/desktop/Style"

const Promo = ({ data }) => {
  const { excerpt, title, content, id, image } = data.contentfulPromo
  return (
    <>
      <Hero image={image.fluid.src}>
        <Title>{title}</Title>
        {excerpt && <Preamble>{excerpt.excerpt}</Preamble>}
        {content && <Text dangerouslySetInnerHTML={{ __html: content.content }}></Text>}
      </Hero>
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPromo(slug: { eq: $slug }) {
      id
      title
      excerpt {
        excerpt
      }
      content {
        content
      }
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default Promo
